/*!
 * Htmx global config
 *  sets up many defaults like error handling, debug extension, and anti forgery token stuff for posts
 */
import htmx from 'htmx.org';
import {querySelectorAllEach} from "./utils.ts";

class HtmxConfig {
    constructor() {
        htmx.config.allowEval = false;
        htmx.defineExtension('debug', {
            onEvent: function (name, evt) {
                if (console.debug) {
                    console.debug(name, evt);
                } else if (console) {
                    console.log("DEBUG:", name, evt);
                } else {
                    throw "NO CONSOLE SUPPORTED"
                }
            }
        });

        htmx.defineExtension('redirect-blank', {
            onEvent: function (name, evt) {
                // detail.elt - the element that dispatched the request
                // detail.xhr - the XMLHttpRequest
                // detail.target - the target of the request
                // detail.requestConfig - the configuration of the AJAX request
                // detail.successful - true if the response has a 20x status code or is marked detail.isError = false in the htmx:beforeSwap event, else false
                // detail.failed - true if the response does not have a 20x status code or is marked detail.isError = true in the htmx:beforeSwap event, else false

                if (name === "htmx:afterRequest") {
                    const url = evt.detail.xhr.getResponseHeader("SSA-HX-Redirect-Blank");
                    if (url) {
                        window.open(url);
                    }
                }
            }
        });

        htmx.defineExtension('preserve-scroll', {
            onEvent: function (name, evt) {
                if (name === "htmx:afterSwap") {
                    var target = evt.detail.elt;
                    // after it is done swapping it loops thru all preserve-scrolls and sets the scroll
                    const scrollsToSet = target.querySelectorAll("[data-preserve-scroll]");
                    for (let i = 0; i < scrollsToSet.length; i++) {
                        scrollsToSet[i].scrollTop = scrollsToSet[i].dataset.preserveScroll;
                    }
                }
            },
            transformResponse: function (text, _, elt) {
                //if the clicked element has the 'data-no-preserve-scroll' attr than just ignore and do not preserve any scrolling.
                if (elt.dataset.noPreserveScroll) return text;

                //parses through the text then selects all the elements that have data-preserve-scroll on them.
                //it will then grab that value and apply it to the new element. This is why we must have IDs so we can save set the scroll back.
                var parser = new DOMParser();
                var htmlDoc = parser.parseFromString(text, 'text/html');
                var scrollsToPreserve = document.querySelectorAll<HTMLElement>("[data-preserve-scroll]");
                for (let i = 0; i < scrollsToPreserve.length; i++) {
                    var id = scrollsToPreserve[i].getAttribute("id");
                    if (!id) throw scrollsToPreserve[i] + "must have an id in order to preserve scroll position";

                    var newEl = htmlDoc.querySelector("#" + id);
                    if (newEl) newEl.setAttribute("data-preserve-scroll", scrollsToPreserve[i].scrollTop.toString());
                }

                return htmlDoc.documentElement.innerHTML;
            }
        });

        // this will be called when new content is loaded in the browser
        htmx.onLoad(function (target) {
            // loops thru all new forms and applies unobtrusive validation.
            const forms = target.getElementsByTagName("form");
            for (let i = 0; i < forms.length; i++) {
                // window.$ assumes jquery is in window so check if it exists
                if(window['jQuery']) {
                    // add validation 
                    window['jQuery'].validator.unobtrusive.parse(forms[i]);
                }
            }
            querySelectorAllEach('.init', el => el.remove());
        });

        //force antiforgery token and default ajax header
        htmx.on('htmx:configRequest', function (evt:CustomEvent) {
            /*evt.detail.headers['X-Requested-With'] = 'XMLHttpRequest';*/
            evt.detail.headers['RequestVerificationToken'] = window['ssaportal'].serverVariables.afk;
        });

        // server replied with anything outside 200-300 status code.
        htmx.on('htmx:responseError', function (evt:CustomEvent) {
            if (evt.detail.xhr.status === 429) {
                alert("You have made too many requests. Please wait a minute and try again.");
                return;
            }

            alert("A server error has occurred. Please refresh the page and try again.");
        });

        //on connection error (ex. cannot reach server)
        htmx.on('htmx:sendError', function () {
            alert("A connection error has occurred. Please refresh the page and try again.");
        });

        //on error occurs during the load handling of an AJAX call
        htmx.on('htmx:onLoadError', function () {
            alert("A connection error has occurred. Please refresh the page and try again.");
        });
    }
}

export {
    HtmxConfig
}

